<template>
  <loading v-if="pageLoading" />

  <div v-else>
    <div
      v-if="adminContext && allowCreate"
      class="message is-dark has-margin-bottom-100"
    >
      <div class="message-body has-padding-75 has-text-dark">
        <apart>
          <p>Submit a manual log entry</p>
          <button class="button is-dark is-rounded is-outlined" @click="addLog">
            Add log entry
          </button>
        </apart>
      </div>
    </div>

    <data-filter
      v-if="showDataFilter && filterable"
      :filters="filters"
      :active-filters="activeFilters"
      @filtersChanged="filtersChanged"
    />

    <b-table
      ref="table"
      :data="table.data"
      :mobile-cards="true"
      :default-sort="[table.sort.field, table.sort.direction]"
      :loading="table.loading"
      :detailed="true"
      :show-detail-icon="false"
      backend-sorting
      striped
      class="has-vertical-align-middle"
      detail-key="_id"
      @sort="onSort"
      @click="openDetails"
    >
      <template slot="empty">
        <no-results
          :title="userId.length ? 'No activities' : 'No logs'"
          :message="
            userId.length
              ? 'There are no activities to show.'
              : 'There are no logs to show.'
          "
          icon="box-open"
        />
      </template>

      <template
        slot-scope="props"
        :has-detailed-visible="typeof props.row.metadata === 'object'"
      >
        <b-table-column label="Event" field="event">
          <log-event :log="props.row" />
        </b-table-column>

        <b-table-column label field="dateCreated" width="160" numeric>{{
          $moment(props.row.dateCreated.toDate()).format("DD/MM/YY HH:mm")
        }}</b-table-column>
      </template>

      <template slot="detail" slot-scope="props">
        <div>
          <vue-json-pretty
            :deep="2"
            :data="cache[props.row._id] || logJSON(props.row)"
            :custom-value-formatter="customValueFormatter"
          />
        </div>
      </template>
    </b-table>

    <load-more
      v-if="!table.complete"
      :loading="table.loading"
      @loadMore="getData(true)"
    />
  </div>
</template>

<script>
import { events } from "@src/services/log";
import { collection, doc, query, where } from "@firebase/firestore";
export default {
  name: "LogsTable",
  components: {
    "log-event": () => import("./_logEvent"),
    "vue-json-pretty": () => import("vue-json-pretty")
  },
  mixins: [require("@mixins/table-with-filtering-and-sorting").default],
  props: {
    userId: {
      type: String,
      default: ""
    },
    siteId: {
      type: String,
      default: ""
    },
    contractId: {
      type: String,
      default: ""
    },
    taskId: {
      type: String,
      default: ""
    },
    event: {
      type: String,
      default: ""
    },
    filterable: {
      type: Boolean,
      required: false,
      default: true
    },
    eventFilter: {
      type: Function,
      default: () => true
    },
    adminContext: {
      type: Boolean,
      required: false,
      default: false
    },
    allowCreate: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      cache: {},
      pageLoading: true,
      siteRef: this.siteId
        ? doc(this.$firestore, `sites/${this.siteId}`)
        : null,
      contractRef: this.contractId
        ? doc(this.$firestore, `contracts/${this.contractId}`)
        : null,
      taskRef: this.taskId
        ? doc(this.$firestore, `tasks/${this.taskId}`)
        : null,
      filters: [
        {
          key: "event",
          label: "Event",
          options: this.$_(events)
            .map((event, key) =>
              this.$_.merge({}, event({}, {}), { value: key })
            )
            .filter(event => event.filter)
            .filter(this.eventFilter)
            .orderBy(["label"], ["asc"])
            .value(),
          type: "select"
        }
      ]
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"]();
    },
    isAdmin() {
      return this.$store.getters["user/isAdmin"]();
    },
    loggedInUserId() {
      return this.$store.getters["auth/userId"]();
    },
    task() {
      return this.$store.getters["tasks/task"](this.taskId);
    }
  },
  methods: {
    openDetails(row) {
      if (
        this.user.isAgent ||
        this.$_.get(row, "metadata.userId", null) === this.loggedInUserId
      )
        this.$refs.table.toggleDetails(row);
    },
    addLog() {
      const metadata = {};

      if (this.userId) metadata.userId = this.userId;
      if (this.siteId) metadata.sitePath = this.siteRef.path;
      if (this.taskId) metadata.taskPath = this.taskRef.path;
      if (this.task) metadata.taskType = this.task.taskType;

      this.$modal.open({
        component: () => import("@shared/logs/_addLogModal"),
        parent: this,
        width: 600,
        hasModalCard: true,
        canCancel: ["escape", "outside"],
        props: {
          metadata
        },
        events: {
          close: () => {
            this.getData();
          }
        }
      });
    },
    getFirestoreRef() {
      let ref = collection(this.$firestore, "logs");
      if (this.userId) {
        ref = query(ref, where("metadata.userId", "==", this.userId));
      }
      if (this.siteId) {
        ref = query(ref, where("metadata.siteRef", "==", this.siteRef));
      }
      if (this.contractId) {
        ref = query(ref, where("metadata.contractRef", "==", this.contractRef));
      }

      if (this.taskId) {
        ref = query(ref, where("metadata.taskRef", "==", this.taskRef));
      }
      if (this.event) {
        ref = query(ref, where("event", "==", this.event));
      }
      return this.buildFirestoreRef(ref);
    },
    afterDataLoad() {
      this.pageLoading = false;
      this.cache = {};
    },
    customValueFormatter(data, key, parent) {
      return `${parent[key]}`;
    },
    logJSON(row) {
      const log = this.$_.cloneDeep(row);
      let result = log.request
        ? {
            Browser: log.request.browser,
            OS: log.request.os,
            IP: log.request.ip
          }
        : {};

      if (this.isAdmin) result["logId"] = row._id;

      if (
        this.$_.get(log, "metadata.userId", null) === this.loggedInUserId &&
        !this.adminContext
      ) {
        this.cache[row._id] = result;
        return result;
      }

      this.$_.keys(log.metadata).forEach(key => {
        const val = log.metadata[key];
        result[key] = this.getLink(key, val);
      });

      this.cache[row._id] = result;
      return result;
    },
    getLink(key, val) {
      if (this.adminContext && key === "invoiceRef")
        return `${val.id}<a href=/admin/invoices/${val.id}><i class="fas fa-external-link-square-alt fa-lg has-margin-left-50" /></a>`;
      else if (this.adminContext && key === "orderRef")
        return `${val.id}<a href=/admin/orders/${val.id}><i class="fas fa-external-link-square-alt fa-lg has-margin-left-50" /></a>`;
      else if (this.adminContext && key === "taskRef")
        return `${val.id}<a href=/tasks/magic/${val.id}><i class="fas fa-external-link-square-alt fa-lg has-margin-left-50" /></a>`;
      else if (this.adminContext && key === "siteRef")
        return `${val.id}<a href=/sites/magic/${val.id}><i class="fas fa-external-link-square-alt fa-lg has-margin-left-50" /></a>`;
      else if (this.adminContext && key === "contractRef")
        return `${val.id}<a href=/admin/contracts/${val.id}><i class="fas fa-external-link-square-alt fa-lg has-margin-left-50" /></a>`;
      else if (this.adminContext && key === "contractId")
        return `${val}<a href=/admin/contracts/${val}><i class="fas fa-external-link-square-alt fa-lg has-margin-left-50" /></a>`;
      else if (this.adminContext && key === "userId")
        return `${val}<a href=/admin/users/${val}><i class="fas fa-external-link-square-alt fa-lg has-margin-left-50" /></a>`;
      else if (this.adminContext && key === "newContractRef")
        return `${val.id}<a href=/admin/contracts/${val.id}><i class="fas fa-external-link-square-alt fa-lg has-margin-left-50" /></a>`;
      else if (this.$_.has(val, "firestore")) {
        // It's a Firestore reference
        return val.path;
      }
      return val;
    }
  }
};
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-wrap;
}
</style>
